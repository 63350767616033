<template>
  <div class="mural py-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
      <router-link to="/" class="imgWelCont">
        <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
      </router-link>
      <div class="txtWelCont">
        <p>Mural</p>
      </div>
    </div>
    <div class="container">
      <div class="col-md-12 mx-auto">
        <div style="height:250px" v-if="load">
          <loader color="#e0a638" size="100" />
        </div>
        <ValidationObserver tag="form" @submit.prevent="sendComent()" ref="mural" v-else>
          <div class="col-md-2 my-3"  v-if="this.sizeWindow > 767">
            <p class="mb-0 titulo_dash">Meu mural</p>
          </div>

          <ValidationProvider
            class="col-md-12 mb-3"
            tag="div"
            v-slot="{ errors, ariaMsg, classes }"
            name="Mural"
          >
            <textarea class="w-100 px-3 py-3 form-control" v-model="note" :class="classes"></textarea>
            <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="col-md-3 ml-auto mb-5">
            <button
              class="btn_default d-flex justify-content-center align-items-center"
              type="submit"
              :disabled="disabled"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="disabled"
              ></span>
              {{!disabled ? 'Salvar' :''}}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <modalDegustacao/>
  </div>
</template>

<script>
import loader from "@/components/utils/_Load";
import HTTP from "@/api_system";
import load from "@/components/utils/_Load";
import modalDegustacao from "@/components/modal/modalDegustacao";
export default {
  components: { loader, modalDegustacao },
  data() {
    return {
      note: "",
      disabled: false,
      load: false,
      id: "",
      sizeWindow:0
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.getComent();
  },
  methods: {
    sendComent() {
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        this.$refs.mural.validate().then(success => {
          if (success) {
            this.disabled = true;
            var json;
            if (this.id != "") {
              json = {
                userId: this.$store.getters.getUser.id,
                note: this.note,
                id: this.id
              };
            } else {
              json = {
                userId: this.$store.getters.getUser.id,
                note: this.note
              };
            }
            HTTP.post("services/app/Comment/CreateOrEdit", json, {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getterToken}`
              }
            }).then(response => {
              this.$notify({
                group: "erros",
                type: "sucess",
                text: `<i class="icon ion-close-circled"></i>Mural alterado`
              });
              this.getComent();
            });
          }
        });
      }
    },
    getComent() {
      this.load = true;
      HTTP.get("services/app/Comment/GetCommentByCurrentUser", {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.load = false;
          this.disabled = false;
          if (response.data.result) {
            this.id = response.data.result.id;
            this.note = response.data.result.note;
          }
        })
        .catch(error => {
          this.load = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    }
  }
};
</script>

<style scoped>
.mural {
  background-color: #2474b2;
}
.btn_default {
  height: 40px;
  border: 1px solid #175d93;
  background-color: #175d93;
}
.btn_default:hover {
  border: 1px solid #f38235;
  background-color: #f38235;
}
textarea {
  height: 350px;
  background-color: rgb(97, 152, 194) !important;
  border: 1px solid rgb(227, 227, 227);
  color: #ffff !important;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 1px;
  font-size: 14px;
}
/* textarea.failed {
  border: 1px solid #dc3545;
}
textarea.is-valid {
  border: 1px solid #28a745;
} */
@media screen and (max-width:767px) {
  .mural{
    padding-top: 0!important;
    padding-left: 0;
    padding-right: 0;
  }
  .welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
    margin-bottom: 15%;
  }
  .imgWelCont{
    width: 55%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 72%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 25%;
  }
  textarea{
    background: #FFFFFF!important;
    border-radius: 8px!important;
    color: black!important;
    height: 445px;
  }
  
}
</style>